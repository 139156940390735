.search__page {
  width: 100vw;
}

.searchPage__logo {
  object-fit: contain;
  height: 50px;
  margin-right: 50px;
}

.searchPage__header {
  width: 100vw;
  display: flex;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  align-items: flex-start;
  padding: 30px;
  border-bottom: 1px solid lightgray;
}

.searchPage__headerBody > .search > .search__input {
    
  margin-top: 0;
  width: unset;
  margin: unset;
  max-width: 800px;
}

.searchPage__options {
  display: flex;
  width: 100vw;
  align-items: center;
  color: gray;
  margin-right: 15%;
  overflow-y: hidden;
  overflow-x: scroll;
  max-width: 100%;
}

.searchPage__options::-webkit-scrollbar {
  display: none;
}

.searchPage__optionsRight a,
.searchPage__optionsLeft a {
  text-decoration: none;
  color: gray;
}

.searchPage__optionsRight,
.searchPage__optionsLeft {
  display: flex;
}

.searchPage__optionsRight {
  margin-left: 80px;
}

.searchPage__option {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.searchPage__option a {
  margin-left: 5px;
}

.searchPage__result {
  margin: 40px 0;
}

.searchPage__resultCount {
  color: #70757a;
  font-size: 14px;
}

.searchPage__results {
  max-width: 650px;
  margin-top: 20px;
  margin-left: 240px;
  margin-bottom: 100px;
}

.searchPage__resultTitle {
  text-decoration: none;
}

.searchPage__resultTitle:hover {
  text-decoration: underline;
}

.searchPage__resultTitle > h2 {
  font-weight: 500;
}

.searchPage__resultSnippet {
  margin-top: 10px;
}

.searchPage_resultImage {
  object-fit: contain;
  height: 50px;
  width: 20px;
  margin-right: 10px;
}

@media only screen and (max-width: 950px) {
  .searchPage-header {
    display: grid;
  }

  .searchPage__headerBody {
    max-width: 85%;
    margin-right: 30px;
  }

  .searchPage__logo {
    margin: 0 30% 10px 30%;
  }

  .searchPage__option {
    width: 50%;
    margin-right: 20px;
  }

  .searchPage__results {
    max-width: unset;
    margin-left: 40px;
    width: 85vw;
  }
}

@media only screen and (max-width: 500px) {
  .searchPage-logo {
    margin: 0 30% 10px 30%;
  }

  .searchPage__results {
    max-width: unset;
    margin-left: 40px;
    width: 95vw;
  }
}
